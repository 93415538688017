<template>
  <div>
    <vue-element-loading
      :active="showSpinner"
      color="rgba(var(--vs-primary), 1)"
      spinner="mini-spinner"/>

    <h2 class="mb-4">
      {{ $t('ProfilePhoto') }}
    </h2>

    <form @submit.prevent class="flex flex-wrap items-center">
      <vs-avatar
        :src="avatarUrl"
        :text="authUser.name"
        color="primary"
        size="70px"
        class="mr-4 mb-4 text-4xl"/>
      <div>
        <vs-button
          v-avoid-key-tab-event
          class="mr-4 sm:mb-0 mb-2"
          @click="showUploadImageModal=true">
          {{ $t('UploadPhoto') }}
        </vs-button>
        <vs-button
          v-if="avatarUrl"
          type="border"
          color="danger"
          @click="confirmRemoveProfileAvatar()">
          {{ $t('$General.Remove') }}
        </vs-button>
        <p class="text-sm mt-2">
          {{ $t('UploadPhotoValidationMsg') }}
        </p>
        <span
          v-show="errors.has($t('ProfilePhoto'))"
          class="text-danger text-sm">
            {{ errors.first($t('ProfilePhoto')) }}
          </span>
      </div>
    </form>

    <vs-popup
      :title="$t('CropPhotoModalTitle')"
      :active.sync="showUploadImageModal"
      class="w-500">
      <transition name="zoom-fade">
        <image-cropper
          v-avoid-key-tab-event
          v-if="showUploadImageModal"
          @cropped="updateProfileAvatar"/>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import UserAccountProfilePhotoCropper from '@/views/modules/user/user-account/user-account-profile/UserAccountProfilePhotoCropper.vue';

/**
 * Component to allow the user view and update its profile photo
 *
 * @module views/modules/user-account/UserAccountProfilePhoto
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} showUploadImageModal - indicate if show or no the upload modal
 * @vue-data {boolean} showSpinner - indicate if show or no the spinner
 * @vue-event {void} updateProfileAvatar - save the updated avatar info
 * @vue-event {void} confirmRemoveProfileAvatar - confirm dialog to remove profile photo
 * @vue-event {void} removeProfileAvatar - remove the current avatar of user
 */
export default {
  name: 'UserAccountProfilePhoto',
  i18n: {
    messages: {
      en: {
        ProfilePhoto: 'Profile photo',
        UploadPhoto: 'Upload photo',
        UploadPhotoValidationMsg: 'Allowed JPG or PNG. Max size of 5MB',
        CropPhotoModalTitle: 'Upload your profile photo',
        UploadedPhotoNotificationTitle: 'Upload profile photo',
        UploadedPhotoNotificationMsg: 'Profile photo uploaded successfully',
        RemovedPhotoNotificationTitle: 'Remove profile photo',
        RemovedPhotoNotificationMsg: 'Profile photo removed successfully',
        RemovePhotoConfirmDialog: {
          Title: 'Confirm remove photo',
          Msg: 'You are about to remove the profile photo',
        },
      },
    },
  },
  components: {
    ImageCropper: UserAccountProfilePhotoCropper,
  },
  data() {
    return {
      showUploadImageModal: false,
      showSpinner: false,
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.user,
    }),
    ...mapGetters({
      avatarUrl: 'auth/avatarUrl',
    }),
  },
  methods: {
    ...mapActions({
      updateAvatar: 'auth/updateAvatar',
      removeAvatar: 'auth/removeAvatar',
    }),
    async updateProfileAvatar(blob) {
      this.showUploadImageModal = false;

      if (blob && !this.errors.any()) {
        this.showSpinner = true;
        await this.updateAvatar(blob);
        this.showSpinner = false;

        this.$showSuccessActionNotification({
          title: this.$t('UploadedPhotoNotificationTitle'),
          text: this.$t('UploadedPhotoNotificationMsg'),
        });
      }
    },
    confirmRemoveProfileAvatar() {
      this.$showConfirmDeleteDialog({
        title: this.$t('RemovePhotoConfirmDialog.Title'),
        text: this.$t('RemovePhotoConfirmDialog.Msg'),
        accept: () => this.removeProfileAvatar(),
        acceptText: this.$t('$General.Remove'),
        cancelText: this.$t('$General.Cancel'),
      });
    },
    async removeProfileAvatar() {
      this.showSpinner = true;
      await this.removeAvatar();
      this.showSpinner = false;

      this.$showSuccessActionNotification({
        title: this.$t('RemovedPhotoNotificationTitle'),
        text: this.$t('RemovedPhotoNotificationMsg'),
      });
    },
  },
};
</script>
